<template>
  <div>
    <van-nav-bar
      left-arrow
      title="作业监督检查记录"
      @click-left="$router.back()"
    />
    <van-tabs v-model="activeName" @click="onTabClick">
      <van-tab title="检查记录" name="1" />
      <van-tab title="问题清单" name="2" />
    </van-tabs>
    <inspect-record v-if="activeName === '1'" />
    <question-list v-else />
  </div>
</template>

<script>
import InspectRecord from "./components/InspectRecord";
import QuestionList from "./components/QuestionList";
export default {
  name: "WorkInspectRecord",
  components: { QuestionList, InspectRecord },
  data() {
    return {
      activeName: "1"
    };
  },
  created() {
    this.activeName = this.$route.query.activeName || "1";
  },
  methods: {
    onTabClick() {
      const path = this.$route.path;
      const query = { ...this.$route.query };
      delete query.beginDate;
      delete query.endDate;
      query.activeName = this.activeName;
      this.$router.replace({ path, query });
    }
  }
};
</script>

<style scoped></style>
